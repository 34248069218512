<template>
  <div class="new">
    <panav :color='color' :bgColor='bgColor' />
    <main>
      <h4>{{name}}</h4>
      <h5>{{desc}}</h5>
      <div v-html="detail"></div>
      <img src="~img/40.jpg" class="fanhui" @click="go">
    </main>
    <horserace :color='noticeColor' :background='background' />
  </div>
</template>

<script>
import panav from "@/components/pc/nav.vue";
import horserace from "@/components/pc/horserace.vue";

export default {
  components: {
    panav,
    horserace
  },
  data() {
    return {
      color: "#64471a",
      noticeColor: "#FFD7D6",
      background: "#64471a",
      bgColor: "#ffd7d6",
      id: "",
      createtime_text: "",
      name: "",
      desc: "",
      detail: ""
    };
  },
  created() {
    this.id = this.getQueryValue("id");
    console.log(this.id);
    this.getNew();
  },
  methods: {
    async getNew() {
      const res = await this.axios.get(`/api/index/new_show?id=${this.id}`);
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        console.log(data);
        this.createtime_text = data.createtime_text;
        this.name = data.name;
        this.desc = data.desc;
        this.detail = this.setimg(data.detail);
      }
    },
    setimg(a) {
      // var a = '<P><img src="http://bbs.cn.yimg.com/user_img/200701/31/jisuanji986_117025184198149.jpg"><img src="https://bbs.cn.yimg.com/user_img/200701/31/jisuanji986_117025184198149.jpg"><img src="/200701/31/jisuanji986_117025184198149.jpg"><img src="/jisuanji986_117025184198149.jpg"></P>';
      // a 为富文本的字符串内容，为了测试，只写了img标签
      var b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g; // img 标签取src里面内容的正则
      var s = a.match(b); // 取到所有img标签 放到数组 s里面
      if (s) {
        for (var i = 0; i < s.length; i++) {
          var srcImg = s[i].replace(b, "$1"); //取src面的内容
          if (srcImg.slice(0, 4) == "http" || srcImg.slice(0, 5) == "https") {
            //若src前4位置或者前5位是http、https则不做任何修改
            console.log("不做任何修改");
          } else {
            //修改富文本字符串内容 img标签src 相对路径改为绝对路径
            a = a.replace(
              new RegExp(srcImg, "g"),
              "http://b.fufu.zbfib.com" + srcImg
            );
          }
        }
      }

      // console.log(a);//打印查看是否修改成功。
      return a;
    },
    go() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="less" scoped>
.new {
  background: #ffd7d6;
  overflow: hidden;
  min-height: 100vh;
  padding-bottom: 100px;
  position: relative;
  main {
    padding-top: 0.72rem;
    text-align: center;
    .fanhui {
      width: 0.4rem;
      position: fixed;
      top: 1.15rem;
      right: 0.3rem;
      cursor: pointer;
    }
    h4 {
      // border-top: 2px solid #64471a;
      padding-top: 0.34rem /* 34/100 */;
      font-size: 0.27rem /* 31/100 */;
      font-family: Antipol, GLEXME;
      color: #64471a;
    }
    h5 {
      font-size: 0.27rem /* 31/100 */;
      font-family: Antipol, GLEXME;
      color: #64471a;
      margin-top: 0.08rem /* 8/100 */;
      margin-bottom: 0.24rem /* 24/100 */;
    }
    div {
      font-family: Antipol, GLEXME;
      max-width: 730px;
      margin: auto;
      color: #64471a;
      /deep/img {
        box-shadow: 2px -1px 17px 16px rgba(251, 251, 251, 0.75);
        margin: 10px auto;
      }
    }
  }
}
</style>




